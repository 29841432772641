export default {
    install(Vue) {
        Vue.prototype.$api = {
            async sendForm(module, data) {
                let returnPromise = new PromiseWrapper();
                let formData = new FormData();
                for(let prop in data) {
                    if(!data[prop]) continue;
                    formData.append(prop, data[prop]);
                }

                let response = await fetch(`/api/${module}`, {
                    method: "POST",
                    mode: "cors",
                    body: formData,
                }).catch(e => {
                    console.error(e);
                    debugger;
                    Vue.prototype.$toast.push("error", "Error al comunicarse con el servidor.");
                });
                if(!response || response.status !== 200) {
                    Vue.prototype.$toast.push("error", "Error al comunicarse con el servidor.");
                    returnPromise.reject();
                } else {
                    response.headers.forEach(e => console.log(e))
                    let blob = await response.blob();
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement('a');
                    a.href = url;
                    a.download = response.headers.get("Content-Disposition").split("filename=")[1];
                    document.body.appendChild(a);
                    a.click();    
                    a.remove();
                    returnPromise.resolve();
                }

                return returnPromise.promise;
            }
        }
    }
}

class PromiseWrapper {
    constructor() {  
        this.promise = new Promise( (res, rej) => {
            this.resolve = res;
            this.reject = rej;
        });
    }
}