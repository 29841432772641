import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router/index'
import vuetify from './plugins/vuetify'
import NotificationService from './plugins/notifications'
import ApiRequest from './plugins/api-request'

Vue.config.productionTip = false
Vue.use(NotificationService);
Vue.use(ApiRequest);

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
