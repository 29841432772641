import Vue from 'vue';

export default {
    install() {
        Vue.prototype.$toast = {
            messageHistory: [],
            listeners: [],
            colors: {
                "error": "red",
                "info": "blue",
                "success": "green"
            },
            push(type, text) {
                let newMessage = {type, text};
                this.messageHistory.push(newMessage);
                this.listeners.forEach(callback => {
                    if(typeof callback === "function")
                        callback(newMessage);
                });
            },
            onNewMessage(callback) {
                if(typeof callback === "function")
                    this.listeners.push(callback);
            },
            retrieve() {
                return this.messages;
            }
        }
    }
}