import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import es from 'vuetify/es5/locale/es';
import afipIcon from '../components/icons/afip.vue';
import obraSocialIcon from '../components/icons/obra-social.vue';
import casDescuentoIcon from '../components/icons/cas_descuento.vue';
import casInformeIcon from '../components/icons/cas_informe.vue';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
      iconfont: "mdi",
      values: {
        afip: {
          component: afipIcon
        },
        obraSocial: {
          component: obraSocialIcon
        },
        casDescuento: {
          component: casDescuentoIcon
        },  
        casInforme: {
          component: casInformeIcon
        }
      },    
    },
    lang: {
      locales: { es },
      current: 'es',
    },
    theme: {
      dark: true
  },
});
