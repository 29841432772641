<template>
  <v-app>
    <LateralMenu/>
    <v-main class="ma-2">
      <router-view></router-view>
    </v-main>
    <v-snackbar top :color="notification.color" center :timeout="notification.timeout || 5000" v-model="notification.show">
      {{ notification.message }}
      <v-btn color="white" text @click="notification.show = false">Close</v-btn>
    </v-snackbar>
    <v-footer app fixed>
			<span>Municipalidad de Rawson&copy; {{ currentYear }}</span>
		</v-footer>
  </v-app>
</template>

<script>
import LateralMenu from './components/LateralMenu';

export default {
  name: 'App',

  components: {
    LateralMenu
  },

  computed: {
    currentYear() {
      return new Date().getFullYear();
    }
  },

  data: () => ({
    drawer: null,
    notification: { 
      message: "",
      show: false,
      timeout: 5000,
      color: ""
    }
  }),
  created() {
    this.$vuetify.theme.dark = true;
    this.$toast.onNewMessage( message => {
      this.notification.message = message.text;
      this.notification.show = true;
      this.notification.color = this.$toast.colors[message.type] || "";
      this.notification.timeout = 3000 + (message.text.length * 50) 
    });
  }
};
</script>
