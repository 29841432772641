<template>
    <div>
        <v-navigation-drawer v-model="drawer" clipped fixed app>
            <v-list dense>
                <v-list-item :to="item.path" v-for="item in items" :key="item.name">
                    <v-list-item-content>
                        <v-icon size="90" v-if="item.icon.startsWith('mdi')"> {{ item.icon }} </v-icon>
                        <v-icon size="90" v-else> $vuetify.icons.{{item.icon}} </v-icon>             
                    </v-list-item-content>
                    <!-- <v-list-item-content>
                        <v-list-item-title>{{ item.name }}</v-list-item-title>
                    </v-list-item-content> -->
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
        <v-app-bar app clipped-left>           
            <v-container fluid>                
                <v-row no-gutters align="center">
                    <v-col md="auto">
                        <v-app-bar-nav-icon @click.stop="drawer = !drawer"/>
                    </v-col>
                    <v-col >
                        <v-toolbar-title>Aplicativos Municipalidad de Rawson</v-toolbar-title>
                    </v-col>                   
                    <v-col md="1">
                        <v-btn class="float-right" icon @click="$vuetify.theme.dark = !$vuetify.theme.dark">
                            <v-icon>mdi-theme-light-dark</v-icon>
                        </v-btn>
                       <!-- <v-btn class="float-right" icon @click="singOut" >
                            <v-icon>mdi-logout</v-icon>
                        </v-btn>>-->
                    </v-col>
                </v-row>
            </v-container>
        </v-app-bar>
    </div>
</template>

<script>
export default {
    data() {
        return {
            drawer: null
        }
    },
    computed: {
        items() {
            return this.$router.options.routes.filter(e => !!e.icon);
        }
    },

    methods: {
        singOut(){
            //localStorage.removeItem('token')
            //this.$router.push('/login')
        }
    },

}
</script>
