import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/afip',
    name: 'AFIP',
    icon: 'afip',
    component: () => import('../views/Afip.vue')
  },
  {
    path: '/cas',
    name: 'CAS',
    icon: 'casDescuento',
    component: () => import('../views/CAS.vue')
  },{
    path: '/cas2',
    name: 'CAS2',
    icon: 'casInforme',
    component: () => import('../views/CAS2.vue')
  },
  {
    path: '/obrasocial',
    name: 'Obra Social',
    icon: 'obraSocial',
    component: () => import('../views/ObraSocial.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
